import React from "react";
import { FiSearch } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import Select from "react-select";

export default function MainSection(props) {
  const location = useLocation().pathname;
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "white",
      padding: "4px 0px",
      outline: "none",
      borderRadius: "6px",
      boxShadow: state.isFocused ? `white` : "none",
      cursor: "pointer",
      // border: "2px solid white",
      // "&:hover": {
      //   border: "2px solid white",
      // },
      border: "1px solid lightgray",
      fontSize: "13px",
    }),
    // menu: (provided) => ({
    //   ...provided,
    //   color: "#00538C",
    // }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "#00538C" : provided.background,
      // color: state.isSelected ? "white" : provided.color,
      cursor: "pointer",
      fontSize: "13px",
    }),
    // singleValue: (provided) => ({
    //   ...provided,
    //   color: "white",
    // }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "white" : "white",
      "&:hover": {
        color: "white",
      },
    }),
  };

  return (
    <div
      className="bg-themeGray p-5 2xl:p-10 w-[80%] min-h-[calc(100vh-64px)] 2xl:min-h-[calc(100vh-92px)] 
    mt-[64px] 2xl:mt-[92px] float-right space-y-5"
    >
      <div className="flex justify-between">
        <h1 className="text-lg 2xl:text-2xl font-semibold text-theme">
          {props.title}
        </h1>
        <div className="relative flex gap-x-2 2xl:gap-x-4">
          {props.search ? (
            <>
              <input
                type="search"
                placeholder="Search for anything..."
                className="bg-white w-[300px] 2xl:w-[350px] pl-9 pr-4 py-2.5 rounded-md outline-none 
                placeholder:text-menuColor placeholder:text-sm"
                onChange={props.searchOnChange}
                value={props.searchValue}
              />
              <FiSearch
                className="absolute top-3.5 left-2"
                size={18}
                color="#00000099"
              />
            </>
          ) : (
            ""
          )}

          {props.filter ? (
            <>
              <div>
                <Select
                  value={props.companyValue}
                  onChange={props.companyOnChange}
                  options={props.companyOptions}
                  inputId="companyType"
                  placeholder="Select Logistic Company"
                  styles={customStyles}
                />
              </div>
              {location === "/by-warehouse-delivery" ||
              props.readyToShipTab === "By Warehouse Delivery Orders" ? (
                <div>
                  <Select
                    value={props.warehouseValue}
                    onChange={props.warehouseOnChange}
                    options={props.warehouseOptions}
                    inputId="warehouseType"
                    placeholder="Select Warehouse"
                    styles={customStyles}
                  />
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      {props.content}
    </div>
  );
}
